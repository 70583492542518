import { Link } from 'react-router-dom'
import classNames from 'classnames'
import * as Tooltip from '@radix-ui/react-tooltip'
import { GraduationHat01 } from '@untitled-ui/icons-react'

import { Body } from '@app/components'
import { RouteType } from '@app/config/router/navMenu'

type Props = RouteType & {
  isActive: boolean
  isExpanded: boolean
  iconClassName?: string
  className?: string
}

export const NavigationItem = ({
  id,
  to,
  Icon = GraduationHat01,
  isActive,
  isExpanded,
  label,
  disabled,
  className,
  iconClassName,
  iconPosition = 'left'
}: Props) => {
  return (
    <Link
      id={id}
      to={disabled ? '#' : to}
      className={classNames(
        'flex w-full cursor-pointer items-center gap-3 rounded-md text-sm text-primary-200 hover:cursor-pointer hover:bg-primary-600',
        isActive && 'rounded-lg !bg-primary-400 shadow-sm',
        iconPosition === 'right' ? 'flex-row-reverse justify-between' : 'justify-left',
        isExpanded && 'pl-2',
        className
      )}
    >
      <Tooltip.Provider>
        <Tooltip.Root delayDuration={0}>
          {isExpanded ? (
            <Icon className={classNames('m-3', iconClassName)} />
          ) : (
            <Tooltip.Trigger>
              <Icon className={classNames('m-2', iconClassName)} />
            </Tooltip.Trigger>
          )}

          <Tooltip.Portal>
            <Tooltip.Content
              sideOffset={15}
              side="right"
              className="z-20 rounded-lg bg-white px-[10px] py-2 shadow-2xl ring-1 ring-primary-200"
            >
              <Body size="m" weight={isActive ? 'bold' : 'medium'}>
                {label}
              </Body>
              <Tooltip.Arrow className="fill-white" />
            </Tooltip.Content>
          </Tooltip.Portal>

          {isExpanded && (
            <Body className="text-primary-200" size="m" weight={isActive ? 'bold' : 'medium'}>
              {label}
            </Body>
          )}
        </Tooltip.Root>
      </Tooltip.Provider>
    </Link>
  )
}
