import { Suspense, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Outlet, useLocation } from 'react-router-dom'

import { Loader } from '@app/components'
import { Language, useGetReleaseNotesQuery, UserRole } from '@app/data'
import { NewClassDialog } from '@app/modules/classroom/view/NewClassPage/NewClassDialog'
import { LogUtils } from '@app/utils/LogUtils'

import { useCheckMaintenance } from '../../data/useCheckMaintenance'
import { ReleaseNotesDialog } from '../ReleaseNotesDialog'
import { SideNavigation } from '../SideNavigation'

import 'swiper/swiper-bundle.css'

export const BasicLayout = () => {
  const [skipFetchingReleaseNotes, setSkipFetchingReleaseNotes] = useState(false)
  const location = useLocation()
  const { isMaintenance } = useCheckMaintenance()

  const { data: releaseNotesData } = useGetReleaseNotesQuery({
    skip: skipFetchingReleaseNotes,
    variables: {
      input: { language: Language.CZECH, userRole: UserRole.TEACHER },
      page: { from: 0, count: 1 }
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    LogUtils.logScreenView(location.pathname)
  }, [location])

  useEffect(() => {
    if (isMobileOnly) {
      const viewportMetaTag = document.querySelector('meta[name="viewport"]')
      if (viewportMetaTag) {
        viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=0.6, maximum-scale=0.8')
      } else {
        const newViewportMetaTag = document.createElement('meta')
        newViewportMetaTag.setAttribute('name', 'viewport')
        newViewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=0.6, maximum-scale=0.8')
        document.head.appendChild(newViewportMetaTag)
      }
    }
  }, [])

  return (
    <div className="flex w-full bg-primary-1000 text-white">
      <NewClassDialog />

      {!skipFetchingReleaseNotes && releaseNotesData?.releaseNotes && releaseNotesData.releaseNotes.length > 0 && (
        <ReleaseNotesDialog
          data={releaseNotesData.releaseNotes}
          onClose={() => {
            setSkipFetchingReleaseNotes(true)
          }}
        />
      )}

      <main className="flex h-full w-full text-white">
        {isMaintenance ? (
          <div className="flex h-screen w-screen overflow-hidden">
            <Suspense>
              <Outlet />
            </Suspense>
          </div>
        ) : (
          <>
            <div className="w-20">
              <SideNavigation />
            </div>
            <div className="primary-1000 h-full w-[calc(100%-5rem)]">
              <Suspense
                fallback={
                  <div className="text-pr flex h-screen w-screen items-center justify-center">
                    <Loader />
                  </div>
                }
              >
                <Outlet />
              </Suspense>
            </div>
          </>
        )}
      </main>
    </div>
  )
}
