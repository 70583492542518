import { Settings01 } from '@untitled-ui/icons-react'

import { EnglishFlagRoundIcon } from '@app/assets/flagIcons/round/EnglishFlagRoundIcon'
import { FranceFlagRoundIcon } from '@app/assets/flagIcons/round/FranceFlagRoundIcon'
import { GermanFlagRoundIcon } from '@app/assets/flagIcons/round/GermanFlagRoundIcon'
import { SpanishFlagRoundIcon } from '@app/assets/flagIcons/round/SpanishFlagRoundIcon'
import { Language } from '@app/data'
import i18n from '@app/locales/i18n'
import { IconType } from '@app/model/common/IconType'

import { Pages } from './PagesEnum'

export type RouteType = {
  id?: string
  to: string
  label: string
  Icon?: IconType
  iconPosition?: 'left' | 'right'
  position?: 'bottom' | 'top' | 'middle'
  disabled?: boolean
  children?: RouteType[]
}

export const navMenu: RouteType[] = [
  {
    to: Pages.LIBRARY(Language.ENGLISH.toLowerCase()),
    label: i18n.t('languages.english'),
    // TODO: fix types
    Icon: EnglishFlagRoundIcon as any,
    iconPosition: 'right',
    position: 'middle'
  },
  {
    to: Pages.LIBRARY(Language.GERMAN.toLowerCase()),
    label: i18n.t('languages.german'),
    // TODO: fix types
    Icon: GermanFlagRoundIcon as any,
    iconPosition: 'right',
    position: 'middle'
  },
  {
    to: Pages.LIBRARY(Language.FRENCH.toLowerCase()),
    label: i18n.t('languages.french'),
    // TODO: fix types
    Icon: FranceFlagRoundIcon as any,
    iconPosition: 'right',
    position: 'middle'
  },
  {
    to: Pages.LIBRARY(Language.SPANISH.toLowerCase()),
    label: i18n.t('languages.spanish'),
    // TODO: fix types
    Icon: SpanishFlagRoundIcon as any,
    iconPosition: 'right',
    position: 'middle'
  },
  {
    to: Pages.SETTINGS,
    label: i18n.t('routes.settings'),
    Icon: Settings01,
    position: 'bottom'
  }
]
